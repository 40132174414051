import semverGte from 'semver/functions/gte';
import semverCoerce from 'semver/functions/coerce';

export const VERSIONS = {
  V1: '0',
  V2: '5.8.9',
};

export default (config, context) => {
  if (config?.appVersion) {
    const appVersion = semverCoerce(config.appVersion);
    if (semverGte(appVersion, VERSIONS.V2)) {
      const AndroidFwf_V2 = require('./androidFwf.v2').default;
      return new AndroidFwf_V2(config, context);
    }
  }

  const AndroidFwf_V1 = require('./androidFwf.v1').default;
  return new AndroidFwf_V1(config, context);
};
