import { v4 as uuidv4 } from 'uuid';
import { listenEvent } from '../../utils/events';
import { isVariation, parseResponse } from '../../utils/flags';
import { EVENTS_LISTEN, TIMEOUT } from '../../constants';

export default class IosFwf {
  promises = {};
  timeout = TIMEOUT;

  constructor({ timeout } = {}, context) {
    if (timeout) this.timeout = timeout;

    listenEvent(EVENTS_LISTEN.FLAG_IS_ENABLED, this.onFlagIsEnabled.bind(this));

    this.setContext(context);
  }

  getFlag(flag, fallback = false) {
    const promise = new Promise((resolve, reject) => {
      const promiseId = uuidv4();

      const timeout = setTimeout(() => {
        this.onFlagIsEnabled({
          detail: {
            promiseId,
            isEnabled: fallback,
            isAbTest: false,
            keyName: flag,
            variation: fallback,
          },
        });
      }, this.timeout);

      this.promises[promiseId] = { resolve, reject, timeout };

      this.postMessage('isEnabled', { promiseId, flag, fallback });
    });

    return promise;
  }

  setContext(newContext = {}) {
    this.postMessage('setGlobal', newContext);
  }

  onFlagIsEnabled({ detail }) {
    if (this.promises[detail.promiseId]) {
      const promise = this.promises[detail.promiseId];

      clearTimeout(promise.timeout);

      if (detail.error) {
        promise.reject(detail);
      } else {
        promise.resolve({
          isEnabled: isVariation(detail.isEnabled),
          isAbTest: detail.isAbTest,
          keyName: detail.keyName,
          variation: parseResponse(detail.isEnabled),
        });
      }
      delete this.promises[detail.promiseId];
    }
  }

  postMessage(command, parameters) {
    const message = {
      command,
      parameters,
    };
    window.webkit.messageHandlers.FwfWebInterface.postMessage(message);
  }
}
