import { NOT_SET } from '../constants';

function shouldReplaceToNotSet(value) {
  return typeof value === 'undefined' || value === null || value === '';
}

export default function mapPropsToTracking(data) {
  try {
    return Object.keys(data).reduce((obj, key) => {
      const value = data[key];
      // eslint-disable-next-line no-param-reassign
      obj[key] = shouldReplaceToNotSet(value) ? NOT_SET : value;
      return obj;
    }, {});
  } catch (err) {
    return data;
  }
}
