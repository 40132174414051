import { EVENT_KEY } from '../constants';

export function listenEvent(event, callback) {
  window.addEventListener(`${EVENT_KEY}:${event}`, callback);
}

export function removeEvent(event, callback) {
  window.removeEventListener(`${EVENT_KEY}:${event}`, callback);
}

export function notify(event, detail) {
  window.dispatchEvent(
    new CustomEvent(`${EVENT_KEY}:${event}`, {
      detail,
    }),
  );
}
