export const LOG_KEY = '[FWF MICROSITE]';

export const EVENT_KEY = 'fwf';

export const EVENTS_LISTEN = {
  REGISTER: 'register',
  SET_CONTEXT: 'setContext',
  GET_FLAG: 'getFlag',
  FLAG_IS_ENABLED: 'flagsIsEnabled',
  TRACK: 'track',
};

export const EVENTS_EMIT = {
  UPDATED: 'updated',
  FLAG_RESULT: 'flagsResult',
};

export const FWF_EVENTS = {
  READY: '@fwf/ON_READY',
  UPDATED: '@fwf/FLAGS_UPDATED_IN_BACKGROUND',
  CONTEXT_CHANGE: '@fwf/RELEVANT_CONTEXT_CHANGE',
  TRACK: '@fwf/EVENT_PUSH_TO_DATALAYER',
};

export const TIME_TO_LIVE = 20;
export const TIMEOUT = 5000;

export const TEST_ENVIRONMENT = 'test';
export const DEV_ENVIRONMENT = 'development';
export const STG_ENVIRONMENT = 'staging';
export const PROD_ENVIRONMENT = 'live';
export const LOCAL_ENVIRONMENT = 'local';

export const FLAGS = {
  LOGIN_CONFIGURATION_COURIER: {
    KEY: 'mweb-courier-login-methods',
  },
};
export const FLAGS_KEYS = Object.values(FLAGS).map((x) => x.KEY);
