function isString(object) {
  return (
    object === '' ||
    (Boolean(object) &&
      (typeof object === 'string' || object instanceof String))
  );
}

const isBoolean = (x) => x === true || x === false;

const isObject = (obj) => typeof obj === 'object';

export function parseResponse(variation) {
  try {
    return JSON.parse(variation);
  } catch {
    return variation;
  }
}

export function isVariation(flagValue) {
  const variation = flagValue || false;

  if (isBoolean(variation)) {
    return variation;
  }

  if (isString(variation)) {
    return variation.includes('Variation');
  }

  if (isObject(variation)) {
    return Object.keys(variation).length > 0;
  }

  return false;
}
