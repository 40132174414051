export function isAndroidFwf() {
  return window?.FwfWebInterface;
}

export function isIosFwf() {
  return (
    window?.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.FwfWebInterface
  );
}

export function isWebFwf() {
  return !isAndroidFwf() && !isIosFwf();
}
