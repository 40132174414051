export const NOT_SET = '(not set)';
export const SESSION_CACHE_KEY = 'TRACKING_DATA';
export const EVENT_KEY = 'tracker';
export const EVENTS = { TRACK: 'track' };

export const LOGIN_SUCCEEDED = 'login.succeeded';
export const LOGIN_FAILED = 'login.failed';
export const REGISTER_COMPLETED = 'register.completed';
export const AUTH_FACEBOOK_CLICKED = 'facebook.clicked';
export const AUTH_GOOGLE_CLICKED = 'authentication_google.clicked';
export const AUTH_MAIL_CLICKED = 'auth_mail.clicked';
export const LOGIN_SIGNUP_CLICKED = 'login_signup.clicked';
export const LOGIN_SIGNUP_LOADED = 'login_signup.loaded';
export const AUTH_FAILED = 'authentication.failed';
export const PHONE_VALIDATION_STARTED = 'phone_validation.started';
export const PHONE_VALIDATION_COMPLETED = 'phone_validation.completed';
export const PHONE_VALIDATION_FAILED = 'phone_validation.failed';
export const PHONE_VALIDATION_CLICKED = 'phone_validation.clicked';
export const MAIL_VALIDATION_CLICKED = 'mail_validation.clicked';
export const MAIL_VALIDATION_LOADED = 'mail_validation.loaded';
export const MAIL_VALIDATION_COMPLETED = 'mail_validation.completed';
export const MAIL_VALIDATION_ERROR = 'mail_validation.error';
export const TOOLTIP_SHOWN = 'tooltip.shown';
export const TOOLTIP_CLICKED = 'tooltip.clicked';

export const ORIGIN = {
  HOME: 'home',
  EMAIL: 'email_login',
};

export const SOCIAL_NETWORK = {
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
};

export const PHONE_TYPE = {
  SMS: 'sms',
  WHATSAPP: 'whatsapp',
};

export const PHONE_SEND_TYPE = {
  SEND: 'send',
  RESENT: 'resent',
};

export const MAIL_SEND_TYPE = {
  SEND: 'send',
  RESEND: 'resend',
  VALIDATION: 'validation',
};

export const EMAIL = 'email';
export const MAIL = 'mail';
export const PHONE = 'phone';
export const OTP = 'otp_email';
export const RESEND_CODE = 'resend_code';
