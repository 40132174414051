export function getCookie(name, options = {}, requestCookies = {}) {
  const { decode = true } = options;
  let documentCookie = '';
  if (typeof window !== 'undefined') {
    documentCookie = window.document.cookie;
  }
  const cookies = Object.assign(
    {},
    parseCookies(documentCookie),
    requestCookies,
  );

  if (cookies.hasOwnProperty(name)) {
    const value = cookies[name];
    return decode ? decodeURIComponent(value) : value;
  }

  return null;
}

function parseCookies(cookieString) {
  return cookieString.split('; ').reduce((acc, cookie) => {
    const [key, value] = cookie.split('=');
    acc[key] = value;
    return acc;
  }, {});
}
