export default class WebFwf {
  fwf = null;

  context = {};
  features = {};
  observers = new Set();

  async getFlag(key) {
    return {
      isEnabled: false,
      isAbTest: false,
      keyName: key,
      variation: null,
    };
  }

  setContext() {
    // This is intentional
  }

  trackFeature() {
    // This is intentional
  }

  subscribeFeatures() {
    // This is intentional
  }

  unsubscribeFeatures() {
    // This is intentional
  }

  subscribeObserver() {
    // This is intentional
  }

  unsubscribeObserver() {
    // This is intentional
  }
}
