import formatEvent from '../utils/formatEvent';

export function androidTrack(event, payload) {
  const dataToTrack = {
    event: formatEvent(event),
    ...payload,
  };

  window?.AnalyticsWebInterface?.logEvent(event, JSON.stringify(dataToTrack));
}

export function isAndroidTrack() {
  return !!window?.AnalyticsWebInterface;
}
