import TagManager from 'react-gtm-module';
import mapPropsToTracking from '../utils/mapPropsToTracking';

function webTrack(event, payload) {
  const data = mapPropsToTracking({
    event,
    ...payload,
  });

  window?.dataLayer?.push(data);
}

export function getWebTracker({ gtmConfig }) {
  if (!gtmConfig) throw new Error('Missing gtmConfig');
  TagManager.initialize(gtmConfig);
  return (event, payload) => {
    try {
      webTrack(event, payload);
    } catch (error) {
      throw new Error('[ERROR][webTrack]', error);
    }
  };
}
