import axiosError from '../utils/axios/error';

class ServiceResponse {
  constructor({ success, data, error }) {
    this.success = success === undefined ? !error : !!success;
    this.data = data;
    this.error = axiosError(error);
  }
}

export default ServiceResponse;
