/*
// eslint-disable-next-line prefer-destructuring
const isObject = require('../object/object').isObject;
const isString = require('../string/isString').default;
const tryToParseJSON = require('../string/tryToParseJSON').default;
const getNestedProp = require('../object/getNestedProp').default;
*/

const isObject = (obj) =>
  obj &&
  typeof obj === 'object' &&
  !Array.isArray(obj) &&
  !(obj instanceof Date);

function isString(object) {
  return (
    object === '' ||
    (Boolean(object) &&
      (typeof object === 'string' || object instanceof String))
  );
}

function getNestedProp(obj, path = '', separator = '.') {
  if (!obj) return undefined;

  const props = path.split(separator);
  return props.reduce((prev, curr) => prev && prev[curr], obj);
}

function tryToParseJSON(str) {
  try {
    const json = JSON.parse(str);
    return { isValid: true, json };
  } catch (e) {
    return { isValid: false, json: str };
  }
}

function parseErrorData(error) {
  const data = getNestedProp(error, 'response.data');
  if (!data) return null;
  if (isObject(data) || !isString(data)) return data;
  const { isValid, json } = tryToParseJSON(data);
  if (isValid) return json;
  return data;
}

function getRequestBody(error) {
  return JSON.parse(getNestedProp(error, 'response.config.data') || '{}');
}

function axiosError(error) {
  if (!error) return null;
  if (isString(error)) return error;

  const message = error.message || '';
  const url = getNestedProp(error, 'config.url') || '';
  const status = getNestedProp(error, 'response.status') || '';
  const errorData = parseErrorData(error);
  const body = getRequestBody(error);

  let response = {
    message,
    networkStatus: status,
    networkUrl: url,
    body,
  };

  if (isObject(errorData)) {
    response = { ...response, ...errorData };
  } else {
    response.data = errorData;
  }

  return response;
}

module.exports = axiosError;
