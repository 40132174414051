import { useContext } from 'react';

import { ToastContext } from '@/src/providers/ToastProvider';

function useToast() {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error(`useToast must be used within an ToastProvider`);
  }

  return context;
}

export default useToast;
