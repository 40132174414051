import parse from './parse';
import stringify from './stringify';
import sortObject from './sortObject';
import { SESSION_CACHE_KEY } from '../constants';

export function getTrackingData() {
  const cachedTrackingData = parse(sessionStorage.getItem(SESSION_CACHE_KEY));
  if (!(cachedTrackingData && cachedTrackingData.value)) return [];
  return cachedTrackingData.value;
}

export function saveTrackingData(data) {
  const trackingData = getTrackingData();
  const date = new Date();
  sessionStorage.setItem(
    SESSION_CACHE_KEY,
    stringify([
      {
        key: date,
        data: sortObject(data),
        date: date.toLocaleString(),
      },
      ...trackingData,
    ]),
  );
}
