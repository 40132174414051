const getRamdomIdentifier = () => {
  let identifier1, identifier2;
  try {
    const array = new Uint32Array(2);
    window.crypto.getRandomValues(array);
    identifier1 = array[0].toString().padStart(18, '0');
    identifier2 = array[1].toString(36).padStart(10, '0');
  } catch (error) {
    identifier1 = Math.round(1e9 * Math.random())
      .toString()
      .padStart(18, '0');
    identifier2 = Math.random().toString(36).slice(2).padStart(10, '0');
    console.error('generateUniqueIdentifier-error: ', error);
  }
  return `${identifier1}.${identifier2}`;
};

export const generateUniqueIdentifier = () => {
  try {
    const uniqueId = `${
      new Date().getTime() + 60 * new Date().getTimezoneOffset()
    }.${getRamdomIdentifier()}`;

    return uniqueId;
  } catch (error) {
    console.error('generateUniqueIdentifier-error: ', error);
    return null;
  }
};
