import { isAndroidFwf, isIosFwf, isWebFwf } from './utils/platform';

let fwf;

function load() {
  let lib;
  if (isAndroidFwf()) {
    lib = require('./platformFwf/androidFwf');
  } else if (isIosFwf()) {
    lib = require('./platformFwf/iosFwf');
  } else {
    lib = require('./platformFwf/webFwf');
  }
  return lib.default;
}

export function getFlag(flag, fallback) {
  return fwf.getFlag(flag, fallback);
}

export function setContext(context = {}) {
  return fwf.setContext(context);
}

export function subscribeFeatures(features = []) {
  if (isWebFwf()) {
    return fwf.subscribeFeatures(features);
  }
}

export function unsubscribeFeatures(features = []) {
  if (isWebFwf()) {
    return fwf.unsubscribeFeatures(features);
  }
}

export function subscribeObserver(callback) {
  if (isWebFwf()) {
    fwf.subscribeObserver(callback);
  }
}

export function unsubscribeObserver(callback) {
  if (isWebFwf()) {
    fwf.unsubscribeObserver(callback);
  }
}

export function trackFeature(flag, value) {
  if (isWebFwf()) {
    fwf.trackFeature(flag, value);
  }
}

export async function initialize({ config = {}, context = {} } = {}) {
  if (!fwf) {
    const getLib = load();
    fwf = getLib(config, context);
  } else {
    setContext(context);
  }

  if (isWebFwf()) {
    return new Promise((resolve) => {
      fwf.subscribeObserver((response) => {
        if (response.type === '@fwf/ON_READY') {
          resolve();
        }
      });
    });
  }
}

export function clear() {
  fwf = null;
}

export function isLoaded() {
  return !!fwf;
}

export default {
  getFlag,
  setContext,
  subscribeFeatures,
  unsubscribeFeatures,
  subscribeObserver,
  unsubscribeObserver,
  trackFeature,
  initialize,
  clear,
  isLoaded,
};
