import React from 'react';
import Script from 'next/script';
import isServer from '@/src/utils/isServer';
import { logError } from '@/src/utils/sentry';
import { initialize as initializeFWF } from '@/external-libs/fwf';
import { initialize as initializeTracker } from '@/external-libs/tracker';
import { setPerseusTrackingCookies } from '@/external-libs/perseus';

const WebScripts = ({ fwfConfig, trackerConfig }) => (
  <Script
    id="web_libraries_start"
    onError={(error) => logError('[SCRIPT_WEBVIEW]', error)}
  >
    {(async () => {
      if (!isServer()) {
        await initTracker(trackerConfig);
        await initFunWithFlags(fwfConfig);
      }
    })()}
  </Script>
);

/**
 * Init the tracker lib for WebView
 * @param {object} config
 */
async function initTracker(config) {
  try {
    initializeTracker(config);
    setPerseusTrackingCookies();
  } catch (error) {
    logError('[TRACKER][INIT]', error);
  }
}

/**
 * Init the fwf lib for WebView
 * @param {object} config
 */
async function initFunWithFlags(config) {
  try {
    await initializeFWF(config);
  } catch (error) {
    logError('[FWF][INIT]', error);
  }
}

export default WebScripts;
