import semverGte from 'semver/functions/gte';
import semverCoerce from 'semver/functions/coerce';
import IosFwf_V1 from './iosFwf.v1';
import IosFwf_V2 from './iosFwf.v2';

export const VERSIONS = {
  V1: '0',
  V2: '10.10.0',
};

export default (config, context) => {
  if (config?.appVersion) {
    const appVersion = semverCoerce(config.appVersion);
    if (semverGte(appVersion, VERSIONS.V2)) {
      return new IosFwf_V2(config, context);
    }
  }

  return new IosFwf_V1(config, context);
};
