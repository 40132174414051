import formatEvent from '../utils/formatEvent';

export function iosTrack(event, payload) {
  const message = {
    command: 'logEvent',
    event: formatEvent(event),
    payload,
  };

  window?.webkit?.messageHandlers?.AnalyticsWebInterface?.postMessage(message);
}

export function isIosTrack() {
  return !!window?.webkit?.messageHandlers?.AnalyticsWebInterface;
}
